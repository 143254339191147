<template>


<b-row>



  <b-modal
      ref="modalScan"
      centered
      size="sm"
      hide-footer
      @hidden="closeModalQR"
      :title="title"
    >
      <div class="d-block text-center">

        <b-row  v-if="isComplete" style="margin-bottom:10px">


            <b-tabs
            class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            content-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1 mt-md-0 sin_padding"
            pills
            fill  
            nav-wrapper-class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            style="    padding: 0;"


            >


            <b-tab @click="turnCameraOn">

            <template #title>

              <feather-icon
                                                      class="color_icon"
                                                        icon="MaximizeIcon"
                                                        size="15"
                                                      />
            <span class="texto">Escanear QR</span>


            </template>


                    <b-row>

                      <b-alert
                        variant="primary"
                        show
                      >
                        <div class="alert-body">
                     

                                
                                  <p style="text-align: left;font-size:13px; margin-bottom:0px">Coloque frente a la cámara un código QR de EQCoop para realizar transferencias interbilleteras de saldo EQCoop, BNB y USDT.</p>

                     
                        </div>
                      </b-alert>

                  
                     


                

                        <qrcode-stream style="margin-top:10px; " @decode="onDecode" v-if="isComplete" :camera="camera" :track="paintBoundingBox" @init="onInit">

                          <div  style="margin: auto;" v-if="loadingCamera">
                            
                            <img

                                  alt="Cargando"
                                  width="50px"
                                  src="/img/loading.svg"
                                  >
                            </div>



                                <div v-if="isPending" class="validation-pending">

                                  <img
                                  style="margin: auto;"
                                    alt="Cargando"
                                    width="50px"
                                    src="/img/loading.svg"
                                    >

                                  
                                </div>

                              

                          </qrcode-stream>


                        
                    </b-row>



        
            </b-tab>

            <b-tab @click="showQRMethod">

            <template #title >
              <feather-icon
                                                      class="color_icon"
                                                        icon="EyeIcon"
                                                        size="15"
                                                      />
                <span class="texto">Ver mi QR</span>



            </template>

            <b-card v-if="showQR" style="margin: 0px;">
            
              <b-row>

                <b-col style="margin-bottom: 10px;" align="center" cols="12" sm="12" md="12" lg="12" xl="12"> 

                <b-alert
                          variant="primary"
                          show
                        >
                          <div class="alert-body">
                      

                                  
                                    <p style="text-align: left;font-size:13px; margin-bottom:0px"> Este es tu código QR, muestráselos a tus contactos para que recibas pagos instantáneos. </p>

                      
                          </div>
                        </b-alert>

                </b-col>

              <b-col v-if="hideQR" align="center" cols="12" sm="12" md="12" lg="12" xl="12"> 

                  <b-skeleton-img   no-aspect height="200px" width="60%" animation="fade"></b-skeleton-img>


                  </b-col>


                  <b-col align="center" cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:15px"> 

                    <vue-qr  style="width: 200px !important;" :callback="getDataURL" :margin="margin" logoBackgroundColor="#fff" backgroundColor="#f0f2f5"  :logoScale="logoScale" :logoMargin="logoMargin" :logoCornerRadius="logoCornerRadius" :text="scanData" :correctLevel="correctLevel" :size="size"></vue-qr>
                  



                  </b-col>

                  <b-col cols="3" sm="3" md="3" lg="3" xl="3" align="center" ></b-col>

                  <b-col v-if="hideQR" cols="3" sm="3" md="3" lg="3" xl="3" align="center" >

                  <b-skeleton height="30px"></b-skeleton>


                  </b-col>
                  <b-col v-else cols="3" sm="3" md="3" lg="3" xl="3" align="center" >

                  <b-link @click="descargar()" class="icon_dashboard"> 


                        <b-avatar
                        size="40"
                        variant="light-primary"
                        style="cursor:pointer;">

                        <span class="d-flex align-items-center">
                          <feather-icon
                          icon="DownloadIcon"
                          size="21"
                        />

                        </span>

                        </b-avatar>

                     

                        </b-link>



                  </b-col>






                  <b-col v-if="hideQR" cols="3" sm="3" md="3" lg="3" xl="3" align="center" >

                    <b-skeleton height="30px"></b-skeleton>



                  </b-col>

                  <b-col v-else cols="3" sm="3" md="3" lg="3" xl="3" align="center" >

                  <b-link @click="shared()" class="icon_dashboard"> 


                        <b-avatar
                        size="40"
                        variant="light-primary"
                        style="cursor:pointer;">

                        <span class="d-flex align-items-center">
                          <feather-icon
                          icon="Share2Icon"
                          size="21"
                        />

                        </span>

                        </b-avatar>

                    

                        </b-link>


                  </b-col>

                  <b-col cols="3" sm="3" md="3" lg="3" xl="3" align="center" ></b-col>

                </b-row> 
            </b-card>

    

            </b-tab>





            </b-tabs>


            </b-row>

         <b-card style="margin-bottom:0px" v-if="showFormCripto">

              <b-form
                autocomplete="off"
                @submit.prevent="onSubmitSendCripto"
                
              >

                  <b-row>

                      <b-col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                    >

                    <b-alert
                        variant="secondary"
                        show
                        style="margin-bottom: 10px !important;"
                      >
                        <div class="alert-body">
                          
                                <p style="text-align: center;font-weight: bold;">Saldo de billetera</p>

                            <p class="saldoDisponible"><span style="font-size:25px">$</span>{{saldoDisponible }}<small style="font-weight: bold; font-size:10px"> USD</small></p>

                        <p style="text-align: center; font-size: 15px;">= {{balanceCripto}}<small style="font-weight: bold; font-size:10px"> {{currency}}</small></p>
                  

                        
                        </div>
                      </b-alert>


                      </b-col>

                      <b-col
                      cols="5"
                      sm="5"
                      md="5"
                      lg="5"
                      xl="5"
                    >

                      <div class="profile-image p-0">
                          <b-avatar
                            size="50"
                            variant="light"
                            :src="photoUser"
                          />
                        </div>
              
                  
                    <h4 :title="displayNameUser" style="    cursor: default;font-size:14px;margin-bottom: 0px;margin-top: 5px;    text-transform: uppercase; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" class="font-weight-bolder">
                        {{ displayNameUser }}
                      </h4>


                      <h6 :title="scanData" class="text-muted" style="  cursor: default;  margin: 0;font-size:12px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                        {{ scanData }}
                      </h6>

                    </b-col>

                      <b-col
                      cols="2"
                      sm="2"
                      md="2"
                      lg="2"
                      xl="2"
                      style="display: flex;"
                    >

                    <svg width="30" height="30"  class="flecha" viewBox="-32 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9.0L441 239c9.4 9.4 9.4 24.6.0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9.0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3.0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/></svg>

                    </b-col>

                    <b-col
                      cols="5"
                      sm="5"
                      md="5"
                      lg="5"
                      xl="5"
                    >

                    <div class="profile-image p-0">
                          <b-avatar
                            size="50"
                            variant="light"
                            :src="photo"
                          />
                        </div>
              
                  
                    <h4 :title="displayName" style="    cursor: default;font-size:14px;margin-bottom: 0px;margin-top: 5px;    text-transform: uppercase; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" class="font-weight-bolder">
                        {{ displayName }}
                      </h4>


                      <h6 :title="dataScan" class="text-muted" style=" cursor: default;   margin: 0;font-size:12px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                        {{ dataScan }}
                      </h6>

                    </b-col>


              <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom:5px"
            >

            <hr>
                      <p
                class="textLabel"
                style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
              >
                Monto en dólares a transferir (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'El monto en dólares (USD) a enviarse en valor de criptomoneda. Este monto debe ser igual o menor a su saldo disponible.'"
                                  title="Monto de transferencia"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

                  <AutoNumericVue
                      v-model="mountSend"
                      required
                      type="text" inputmode="decimal"
                      :readonly="isDisabled"
                      @input="handleInput2"
                      @blur="handleInput"
                      class="autonumeric_input"
                      :options="json_opciones"
                      :placeholder="'$0.00'"
                  ></AutoNumericVue>

                  <p style="text-align: center;font-style:italic; font-size: 12px; font-weight: bold; margin: 0;">= {{mountTransferencia}} {{currency}}</p>
            
            
            </b-col>



            <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
              <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                Descripción de la transferencia (Opcional):</p>

                 <b-form-textarea
                v-model="description"
                placeholder="Ingrese una descripción corta de la transferencia (Opcional)"
                rows="3"
                no-resize
                :readonly="isDisabled"
                :state="description.length <= maxChar"
                class="char-textarea"
                :class="description.length >= maxChar ? 'text-danger' : ''"
              />
              <small
                class="textarea-counter-value float-left"
                :class="description.length >= maxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ description.length }}</span> / {{ maxChar }}
              </small>

          </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

           

              <p
                class="textLabel"
                style="text-align: center;margin-top: 0px;margin-bottom: 10px;"
              >
                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

              <PincodeInput
                v-model="pin"
                placeholder="-"
                :length="6"
                :autofocus="false"
                :secure="true"
                :disabled="isDisabled"
               
                required
              />

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

              <b-button
                id="btn_transferir"
                ref="btn_transferir"
                type="submit"
                variant="primary"
                :disabled="isDisabled"
              >

                Transferir criptomonedas

              </b-button>

            </b-col>


              </b-row>
              </b-form>
            </b-card>

            <b-card style="margin-bottom:0px"  v-if="showFormPay">
        <b-row>

           <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

                 <b-alert
                variant="primary"
                show
                style="margin-bottom: 10px !important;"
              >
                <div class="alert-body">
                  
                   <p class="saldoDisponible"><span style="font-size:25px">$</span>{{saldoDisponible}}<small class="currency_cripto">USD</small></p>
                   <p style="font-size: 12px;    margin: 0;">Saldo disponible para pagar</p>


                
                </div>
              </b-alert>

         
              </b-col>

      


               <b-col
               cols="5"
              sm="5"
              md="5"
              lg="5"
              xl="5"
            >

              <div class="profile-image p-0">
                  <b-avatar
                    size="50"
                    variant="light"
                    :src="photoUser"
                  />
                </div>
      
          
            <h4 :title="displayNameUser" style="    cursor: default;font-size:14px;margin-bottom: 0px;margin-top: 5px;    text-transform: uppercase; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" class="font-weight-bolder">
                {{ displayNameUser }}
              </h4>


              <h6 :title="scanData" class="text-muted" style="  cursor: default;  margin: 0;font-size:12px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                {{ scanData }}
              </h6>

            </b-col>

               <b-col
               cols="2"
              sm="2"
              md="2"
              lg="2"
              xl="2"
              style="display: flex;"
            >

            <svg width="30" height="30"  class="flecha" viewBox="-32 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9.0L441 239c9.4 9.4 9.4 24.6.0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9.0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3.0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/></svg>

            </b-col>

            <b-col
               cols="5"
              sm="5"
              md="5"
              lg="5"
              xl="5"
            >

             <div class="profile-image p-0">
                  <b-avatar
                    size="50"
                    variant="light"
                    :src="photo"
                  />
                </div>
      
          
            <h4 :title="displayName" style="    cursor: default;font-size:14px;margin-bottom: 0px;margin-top: 5px;    text-transform: uppercase; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" class="font-weight-bolder">
                {{ displayName }}
              </h4>


              <h6 :title="dataScan" class="text-muted" style=" cursor: default;   margin: 0;font-size:12px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                {{ dataScan }}
              </h6>

            </b-col>

            
        <b-form
          autocomplete="off"
          @submit.prevent="onSubmit"
        >
        <b-row>



            <b-col
             cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

            <hr>

                        <p
                class="textLabel"
                style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
              >
                Monto a transferir a tu contacto (*):</p>

                <AutoNumericVue
                v-model="mount"
                type="text" inputmode="decimal"
                required
                :readonly="isDisabled"
                :autofocus="false"
                @blur="onBlurMount"
                class="autonumeric_input"
                :options="json_opciones"
                :placeholder="'$0.00'"
            ></AutoNumericVue>

            </b-col>

            <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >
              <p class="textLabel" style="text-align: left;margin-top: 0px;margin-bottom: 5px;">
                Descripción de la transferencia (Opcional):</p>

                 <b-form-textarea
                v-model="description"
                placeholder="Ingrese una descripción corta de la transferencia (Opcional)"
                rows="3"
                no-resize
                :readonly="isDisabled"
                :state="description.length <= maxChar"
                class="char-textarea"
                :class="description.length >= maxChar ? 'text-danger' : ''"
              />
              <small
                class="textarea-counter-value float-left"
                :class="description.length >= maxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ description.length }}</span> / {{ maxChar }}
              </small>

          </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
              <p
                class="textLabel"
                style="text-align: center;margin-top: 10px;margin-bottom: 10px;"
              >
                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

              <PincodeInput
                v-model="pin"
                placeholder="-"
                :length="6"
                :autofocus="false"
                 :disabled="isDisabled"
                :secure="true"
                required
              />

            </b-col>



            <b-col
            cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

              <b-button
                id="btn_transferir"
                ref="btn_transferir"
                type="submit"
                variant="primary"
                block
                :disabled="isDisabled"
              >

                Transferir dinero

              </b-button>

            </b-col>

            
        

            </b-row>

            </b-form>


        </b-row>

        </b-card>


        <b-card style="margin-bottom:0px"  v-if="showFormNegocio">
        <b-row>

           <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

                 <b-alert
                variant="primary"
                show
                style="margin-bottom: 10px !important;"
              >
                <div class="alert-body">
                  
                   <p class="saldoDisponible"><span style="font-size:25px">$</span>{{saldoDisponible}}<small class="currency_cripto">USD</small></p>
                   <p style="font-size: 12px;    margin: 0;">Saldo disponible para pagar</p>


                
                </div>
              </b-alert>

         
              </b-col>

      


               <b-col
               cols="5"
              sm="5"
              md="5"
              lg="5"
              xl="5"
            >

              <div class="profile-image p-0">
                  <b-avatar
                    size="50"
                    variant="light"
                    :src="photoUser"
                  />
                </div>
      
          
            <h4 :title="displayNameUser" style="    cursor: default;font-size:14px;margin-bottom: 0px;margin-top: 5px;    text-transform: uppercase; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" class="font-weight-bolder">
                {{ displayNameUser }}
              </h4>


              <h6 :title="scanData" class="text-muted" style="  cursor: default;  margin: 0;font-size:12px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                {{ scanData }}
              </h6>

            </b-col>

               <b-col
               cols="2"
              sm="2"
              md="2"
              lg="2"
              xl="2"
              style="display: flex;"
            >

            <svg width="30" height="30"  class="flecha" viewBox="-32 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9.0L441 239c9.4 9.4 9.4 24.6.0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9.0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3.0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/></svg>

            </b-col>

            <b-col
               cols="5"
              sm="5"
              md="5"
              lg="5"
              xl="5"
            >

             <div class="profile-image p-0">
                  <b-avatar
                    size="50"
                    variant="light"
                    :src="photo"
                  />
                </div>
      
          
            <h4 :title="displayName" style="    cursor: default;font-size:14px;margin-bottom: 0px;margin-top: 5px;    text-transform: uppercase; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" class="font-weight-bolder">
                {{ displayName }}
              </h4>


              <h6 :title="dataScan" class="text-muted" style=" cursor: default;   margin: 0;font-size:12px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                {{ dataScan }}
              </h6>

            </b-col>

            
        <b-form
          autocomplete="off"
          @submit.prevent="onSubmitNegocio"
        >
        <b-row>



            <b-col
             cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

            <hr>

            <b-alert
                variant="secondary"
                show
                style="margin-bottom: 10px !important;"
              >
                <div class="alert-body">
                  
                   <p class="saldoDisponible"><span style="font-size:25px">$</span>{{mountNegocio}}<small class="currency_cripto">USD</small></p>
                   <p style="font-size: 12px;    margin: 0;">Cantidad a pagar</p>


                
                </div>
              </b-alert>



            </b-col>

            <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"

            v-if="hasDescription"
          >

                        
              <b-alert  variant="secondary" style="margin-top: 5px !important; " show>
                  <div class="alert-body">

                    <p style="margin-bottom:5px; font-weight: bold;font-size:12px; text-align:left">Descripción del pago: </p>
                    <p style="margin-bottom:5px; font-weight: bold;font-size:12px;text-align:justify">{{ descriptionNegocio }}</p>


        

                  
                
                  
                  </div>
                </b-alert>
  


          </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
              <p
                class="textLabel"
                style="text-align: center;margin-top: 10px;margin-bottom: 10px;"
              >
                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

              <PincodeInput
                v-model="pin"
                placeholder="-"
                :length="6"
                :autofocus="false"
                 :disabled="isDisabled"
                :secure="true"
                required
              />

            </b-col>



            <b-col
            cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

              <b-button
                id="btn_payNegocio"
                ref="btn_payNegocio"
                type="submit"
                variant="primary"
                block
                :disabled="isDisabled"
              >

                Pagar ${{ mountNegocio }} USD

              </b-button>

            </b-col>

            
        

            </b-row>

            </b-form>


        </b-row>

        </b-card>

        
        <b-card style="margin-bottom:0px"  v-if="successPay">


           <b-row>

            <b-col
             cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              align="center"
              style="margin-bottom: 10px;"
            >

             <img  src="/img/check.svg" alt="Pago exitoso" />
            
            </b-col>

                    <b-col
               cols="5"
              sm="5"
              md="5"
              lg="5"
              xl="5"
            >

              <div class="profile-image p-0">
                  <b-avatar
                    size="50"
                    variant="light"
                    :src="photoUser"
                  />
                </div>


              <h6 :title="scanData" class="text-muted" style="cursor: default;margin-top: 5px;font-size:12px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                {{ scanData }}
              </h6>

            </b-col>

               <b-col
               cols="2"
              sm="2"
              md="2"
              lg="2"
              xl="2"
              style="display: flex;"
            >

            <svg width="30" height="30"  class="flecha" viewBox="-32 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9.0L441 239c9.4 9.4 9.4 24.6.0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9.0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3.0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/></svg>

            </b-col>

            <b-col
               cols="5"
              sm="5"
              md="5"
              lg="5"
              xl="5"
            >

             <div class="profile-image p-0">
                  <b-avatar
                    size="50"
                    variant="light"
                    :src="photo"
                  />
                </div>
      
          
              <h6 class="text-muted" style=" margin-top: 5px; font-size:12px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                {{ dataScan }}
              </h6>

            </b-col>


            <b-col
               cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
            <p style="text-align: center;font-size: 13px;margin-bottom: 5px;margin-top: 15px;">Enviado al usuario</p>

                <h4 :title="displayName" style="    cursor: default;font-size:16px;margin-bottom: 0px;margin-top: 5px;    text-transform: uppercase; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" class="font-weight-bolder">
                {{ displayName }}
              </h4>

               <p style="text-align: center;font-size: 30px;font-weight: bold;    margin-top: 15px;"><span style="font-size:15px;">$</span>{{mount}} <span style="font-size:15px;">USD</span></p>

              <p style="text-align: center;font-size: 12px;margin: 0;">{{date}}</p>

              <b-alert v-if="hasDescription" variant="primary" style="margin-top: 5px !important; " show>
              <div class="alert-body">

         

                    <p style="font-size: 14px; margin-bottom:0;">{{description}}</p>


              
            
              
              </div>
            </b-alert>



            </b-col>


            <b-col
            cols="12"
              sm="12"
              md="12"
              lg="6"
              xl="6"
              style="margin-top: 10px !important; "
            >

              <b-button
                type="submit"
                variant="primary"
                block
                @click="otherPay()"
              >

                           <feather-icon
                                      icon="MaximizeIcon"
                                      size="15"
                                      style="margin-right: 5px;cursor:pointer;"
                                      />  Realizar otro pago

              </b-button>

            </b-col>

            
             <b-col
            cols="12"
              sm="12"
              md="12"
              lg="6"
              xl="6"
              style="margin-top: 10px !important; "
            >
                <b-button
                type="submit"
                variant="primary"
                block
                @click="exit()"
              >

                          <feather-icon
                                      icon="LogOutIcon"
                                      size="15"
                                      style="margin-right: 5px;cursor:pointer;"
                                      />  Finalizar

              </b-button>
            
            </b-col>

                 

           
           
           </b-row>

</b-card>

      </div>

    </b-modal>



</b-row>



</template>

<script>
import {
   BRow,BSkeleton, BNavbarNav,BLink,BForm,BFormInput,BTabs,BSkeletonImg, BTab, BNavItem,BFormTextarea,BAvatar, VBTooltip, BButton,VBPopover, BAlert,BCol, BCard,
} from 'bootstrap-vue'
import PincodeInput from 'vue-pincode-input'
import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
import VueQr from 'vue-qr'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

export default {
  components: {
    BForm,
    QrcodeStream, QrcodeDropZone, QrcodeCapture,
    VueQr,
    BButton,
    BAvatar,
    BTabs, BTab,
    BFormTextarea,
    BLink,
    BSkeletonImg,
    BSkeleton,
    BFormInput,
    AutoNumericVue,
    PincodeInput,
    BAlert,
    BCol,
    BCard,
    BRow,
    BNavbarNav, BNavItem,

  },
  props: ['userId','tokenAuth','alias','phone','photoUser','email', 'displayNameUser', 'isMobile'],
    directives: {
          'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  data() {



    let scanData="";


    if(this.alias === ""){

      if(this.phone === ""){
        scanData= this.email+";interbilletera";
      }else{
        scanData= this.phone+";interbilletera";
      }


    }else{
      scanData= this.alias+";interbilletera";
    }

 
    return {
  

      showFormTransferencias:false,
      mount:0,
      showQR:false,
      hasDescription:false,
       description:"",
            maxChar: 75,
      json_opciones:{},
       error:"",
         camera: "",
       loadingCamera:false,
       isPending: false,
       isComplete:true,
       showFormPay:false,
       showFormNegocio:false,
       showFormCripto:false,
       successPay:false,
       title:"Pagos rápidos con QR",
       saldoDisponible: 0,
       displayName: "",
       photo: "",
       date:"",
        dataScan: "",
        hideQR:true,
        dataUrl:"",
        pin:"",
        scanData:scanData,
        margin:50,
      logoScale:0.3,
            logoMargin: 1,
            logoCornerRadius:100,
      correctLevel:3,
      size:800,
      balanceCripto:0,
      priceCripto:0,
      currency:"",
      checkStatus:0,
      mountSend:null,
      isDisabled:false,
      mountTransferencia:"0.0000",
      idWalletUserSend:"",
      idWalletUserReceive:"",
      mountNegocio:0,
      origen:"",
      descriptionNegocio:"",

    
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {




       this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:1000000, modifyValueOnWheel: false };


    this.$eventBus.$on('openScan', () => {
  
      this.scan();
    });


    this.scan();



  },
  methods: {

    handleInput2(value){


      if(value === "" || value === "null" || value === null  ){


     
       

        this.mountTransferencia="0.0000";

   


     }else{






 
               let mounTrans=Number(value)  / Number(this.priceCripto);

                 if(Number(mounTrans.toString().length) > 9){
                     this.mountTransferencia= Number(mounTrans.toString().substring(0, 9));
                 }else{

                   this.mountTransferencia= mounTrans;

                 }


           


     }

},

handleInput () {


var t = this.mountSend;

   if(t === null  ){

  

        this.mountTransferencia="0.0000";
      


     }else{

   

  if(Number(this.mountSend) > Number(this.saldoDisponible)){



      this.$toast.error("El monto $"+this.mountSend+" USD supera su saldo de billetera.", {
     position: 'top-right',
     timeout: 3010,
     closeOnClick: true,
     pauseOnFocusLoss: false,
     pauseOnHover: true,
     draggable: true,
     draggablePercent: 0.4,
     showCloseButtonOnHover: false,
     hideProgressBar: true,
     closeButton: 'button',
     icon: true,
     rtl: false,
   });

           this.mountSend ="";
       this.mountTransferencia="0.0000";
   

  }else{

      
   



 
     let mounTrans=Number(this.mountSend)  / Number(this.priceCripto);

       if(Number(mounTrans.toString().length) > 9){
           this.mountTransferencia= Number(mounTrans.toString().substring(0, 9));
       }else{

         this.mountTransferencia= mounTrans;

       }

     

  }




     }

   



  



},


       exit(){

          this.$refs.modalScan.hide();

    },      otherPay(){

         this.showFormPay=false;
         this.showFormNegocio=false;
         this.showFormCripto=false;
         this.successPay=false;
         this.isComplete=true;
           this.turnCameraOn();

    },paintBoundingBox (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode

        ctx.lineWidth = 2
        ctx.strokeStyle = '#007bff'
        ctx.strokeRect(x, y, width, height)
      }
    },onDecode (content) {

        this.dataScan= content;
        this.turnCameraOff();
        this.isPending=true;

        if(this.dataScan.includes(";")){

            const myArray = this.dataScan.split(";");

            this.dataScan= myArray[0];
            this.origen= myArray[1];

            if(this.origen === "Roomies"){

              this.$eventBus.$emit('openModalRoomie', this.dataScan)
              this.closeModalQR();

            }else{

              
              if(Number(myArray.length) === Number("4")){

                                
                  this.mountNegocio= Number(myArray[2]).toFixed(2);

                  this.descriptionNegocio=myArray[3];
                  this.hasDescription=true;



                  }else{


                  if(Number(myArray.length) === Number("3")){

                    this.mountNegocio= Number(myArray[2]).toFixed(2);

                    this.descriptionNegocio="";
                    this.hasDescription=false;

                  }




                  }




                  if(this.dataScan.includes("@") || this.dataScan.includes("+") || Number(this.dataScan.length) <= Number("20") || Number(this.dataScan.length) >= Number("64") ){

                  const data_json = { userId: this.userId, dataScan: this.dataScan }

                    
                    this.$https.post('/interbilletera/searchUserQR/', { tokenAuth: this.tokenAuth, data: data_json}).then(response => {
                                  
                      this.isPending=false;
                                if (response.data.code == 200) {

                                  this.isComplete=false;

                                  this.title= "Pago con saldo EQCoop";
                                  this.date="";

                                    this.saldoDisponible= response.data.balance;
                                    this.displayName= response.data.displayName;
                                    this.photo= response.data.photo;
                                    

                                      this.isDisabled=false;
                                      this.pin="";
                                      this.mount="";
                                      this.description="";

                                      if(response.data.is_local){

                                        this.showFormNegocio=true;
                                        this.showFormPay=false;
                                        this.showFormCripto=false;
                                      }else{
                                        this.showFormPay=true;

                                        this.showFormNegocio=false;
                                  
                                        this.showFormCripto=false;

                                      }

                                
                                  this.successPay=false;    
                              
                      
                                } else {

                                  this.showFormPay=false;
                                  this.successPay=false;  
                                  this.isComplete=true;

                                  this.turnCameraOn();

                                      this.$toast.error(response.data.message, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: true,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                      })


                                  if (response.data.code == 401) {
                                    
                                    localStorage.removeItem('userData')



                                    
                                    

                                    

                                    
                                    

                                    // Redirect to login page
                                    this.$router.push({ name: 'auth-login' })
                                  }
                                }
                              }).catch(error => {

                                  this.showFormPay=false;
                                  this.successPay=false;  
                                  this.isComplete=true;

                                this.turnCameraOn();
                                
                                  this.$toast.error("Ocurrió un error inesperado", {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: true,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                      })
                                
                              })




                  }else{

                  this.idWalletUserReceive= myArray[0];

                                            

                      const data_json = { userId: this.userId, id: this.idWalletUserReceive }



                        this.$httpsCryptos.post('/main/searchQRWallets/', { tokenAuth: this.tokenAuth, datos: data_json}).then(response => {
                                
                          this.isPending=false;
                                    if (response.data.code == 200) {

                                      this.isComplete=false;

                                      this.title= "Pago con criptomonedas";

                                      this.balanceCripto= response.data.balanceCripto;
                                      this.priceCripto= response.data.priceCripto;
                                      this.saldoDisponible= Number(this.balanceCripto) * Number(this.priceCripto)
                                      this.scanData=response.data.addressSend;
                                      this.photo= response.data.photo;
                                      this.idWalletUserSend=response.data.idWallet;
                                      this.currency= response.data.currency;
                                      this.dataScan=response.data.addressReceive;
                                      this.displayName= response.data.displayName;

                                      this.showFormPay=false;

                                      this.showFormNegocio=false;

                                      this.showFormCripto=true;

                                  
                                      this.checkStatusWallets();

                          
                                    } else {

                                      this.showFormCripto=false;
                                    
                                      this.isComplete=true;

                                      this.turnCameraOn();

                                          this.$toast.error(response.data.message, {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: true,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                          })


                                      if (response.data.code == 401) {
                                        
                                        localStorage.removeItem('userData')



                                        
                                        

                                        

                                        
                                        

                                        // Redirect to login page
                                        this.$router.push({ name: 'auth-login' })
                                      }
                                    }
                                  }).catch(error => {

                                    this.showFormCripto=false;
                                    
                                      this.isComplete=true;

                                    this.turnCameraOn();
                                    
                                      this.$toast.error("Ocurrió un error inesperado", {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: true,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                          })
                                    
                                  })




                  }



            }

           


        



        }else{
          this.$refs.modalScan.hide();
          this.$toast.error("QR no válido, comunicarse con el dueño del QR para que le genere uno nuevo.", {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: true,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                              })


        }
      


    
    },
    getDataURL(dataUrl,id){

  

this.dataUrl= dataUrl;
this.hideQR=false;


},

checkStatusWallets
(){

 
this.checkStatus = setInterval(() => {




   this.$httpsCryptos.post('/main/priceCripto/', { tokenAuth: this.tokenAuth, currency: this.currency}).then(response => {
       

     if (response.data.code == 200) {


       this.priceCripto= response.data.balance;



       if(this.mountSend != null  ){

        let mounTrans=Number(this.mountSend)  / Number(this.priceCripto);

          if(Number(mounTrans.toString().length) > 7){
              this.mountTransferencia= Number(mounTrans.toString().substring(0, 7));
          }else{

            this.mountTransferencia= mounTrans;

        }


       }

     


     } else {




       if (response.data.code == 401) {
         
         localStorage.removeItem('userData')



         
         
         this.$toast.error(response.data.message, {
           position: 'top-right',
           timeout: 3010,
           closeOnClick: true,
           pauseOnFocusLoss: false,
           pauseOnHover: true,
           draggable: true,
           draggablePercent: 0.4,
           showCloseButtonOnHover: false,
           hideProgressBar: true,
           closeButton: 'button',
           icon: true,
           rtl: false,
         })
         

         
         

         // Redirect to login page
         this.$router.push({ name: 'auth-login' })
       }
     }
   }).catch(error => {
     
   })


     


   
   
   }, 30000)

},

descargar(){

var link = document.createElement("a");
link.download = "Mi código QR";
link.href =this.dataUrl;
link.click();

 

},

shared(){



fetch(this.dataUrl).then(function (response) {
  return response.blob()
}).then(function (blob) {
  let file = new File([blob], "QR.jpg", {type: 'image/jpeg'});
  let filesArray = [file];
  if (navigator.canShare && navigator.canShare({files: filesArray})) {
    navigator.share({
      files: filesArray
    }).then(() => console.log('Successful share'))
      .catch((error) =>   this.$toast.error("Ocurrió un error inesperado", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      }));
  }else{

      this.$toast.error("Lo sentimos, tu navegador no es compatible para compartir este contenido", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })

  }
});




},

    closeModalQR(){
      this.$eventBus.$emit('reiniciarCodigoQR')
      this.$eventBus.$emit('reiniciarEnviar');
      clearInterval(this.checkStatus);

    },

      async onInit (promise) {
        this.loadingCamera = true
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: debes otorgar permiso de acceso a la cámara."
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no se encontró una cámara disponible en este dispositivo"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: se requiere contexto seguro (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: ¿La cámara ya está en uso?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: las cámaras instaladas no son adecuadas"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API no es compatible con este navegador"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: el acceso a la cámara solo está permitido en un contexto seguro. Use HTTPS o localhost en lugar de HTTP';
        } else if(error.name === 'OverconstrainedError'){
          this.error = 'Fallo al abrir la cámara trasera de su dispositivo.';
          }else {
          this.error = `ERROR: Cámara error (${error.name})`;
        }

         this.$refs.modalScan.hide();

         this.$toast.error(this.error, {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: true,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            });

            this.$eventBus.$emit('reiniciarEnviar')
            
            


      }finally {
        this.loadingCamera = false
      }
    },
        turnCameraOn () {

          if(!this.isMobile){
                     //es destopk
                     this.camera = 'auto'

          }else{

             //es tablet o celular
             this.camera = 'rear'

          }
     
    },

    turnCameraOff () {
      this.camera = 'off'
    
    },
    showQRMethod () {
      this.camera = 'off'
      this.showQR=true;
    },

    scan(){



       this.turnCameraOn();

       this.isPending=false;

         this.isComplete=true;

          this.showFormPay=false;
          this.showFormCripto=false;
           this.successPay=false;  
          this.title= "Pagos rápidos con QR";

        this.$refs.modalScan.show();

    },
    onSubmitNegocio(event){
      event.preventDefault();

          const userId_json = {
            userIdWhoPay: this.userId, pin: this.pin, mount: this.mountNegocio, description: this.descriptionNegocio, dataScan:this.dataScan, origen: this.origen
          }



          const user_string = JSON.stringify(userId_json)

          const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
        
          this.isDisabled = true

          document.getElementById('btn_payNegocio').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Realizando pago'

          this.$https.post('/interbilletera/payWithQR/', { tokenAuth: this.tokenAuth, data: dataEncripted}).then(response => {
          this.isDisabled = false

        
      
            document.getElementById('btn_payNegocio').innerHTML = 'Pagar $'+ this.mountNegocio+" USD";
          

            if (response.data.code == 200) {

              this.date= response.data.date;
              this.mount= this.mountNegocio;
              this.title="Transacción exitosa";

              this.showFormNegocio=false;

              

              this.successPay=true;          

              this.$toast.success(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              });

              this.$eventBus.$emit('reiniciarListadoInterbilletera');

              this.$eventBus.$emit('reiniciarEnviar')

              this.$eventBus.$emit('reiniciarEnviar2');
              this.$eventBus.$emit('reiniciarTransaccionesHome');


            } else {
              this.isDisabled = false

              this.$toast.error(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

              if (response.data.code == 401) {
                
                localStorage.removeItem('userData')


                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
              } else if (response.data.code == 503) {
                this.pin = ''
              } else if(response.data.code == 501){
                      this.$swal({
                          title: 'Tu perfil debe estar verificado para realizar esta acción',
                          text: `¿Deseas verificar tu cuenta ahora?`,
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Verificar cuenta',
                          cancelButtonText: 'No',
                          customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-secondary ml-1',
                          },
                          buttonsStyling: false,
                        }).then(result => {
                          if (result.value) {

                            this.$router.push({ name: 'perfil', hash: '#verificacion' })

                          }
                        })
                    }
            }
          }).catch(error => {
            this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
          })
          
    },
    onSubmitSendCripto(event){
      event.preventDefault();

      if(Number(this.description.length) > Number(this.maxChar)){

            this.description="";

              this.$toast.error("El texto de la descripción sobrepasa el límite permitido de 75 caracteres", {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

            }else{

            this.mountSend= Number(this.mountSend).toFixed(2);

            if(Number(this.description.length) > Number("0")){
              this.hasDescription=true;

            }else{
              this.hasDescription=false;
            }

            const userId_json = {
              userId: this.userId,idWalletUserReceive:this.idWalletUserReceive, pin: this.pin, mount: this.mountSend, description: this.description, idWalletUserSend:this.idWalletUserSend, origen: this.origen
            }



            const user_string = JSON.stringify(userId_json)

            const dataEncripted = this.$encryptCyptos.encrypt(user_string)

            this.isDisabled = true

            document.getElementById('btn_transferir').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Transferiendo'

            this.$httpsCryptos.post('/main/payWithQR/', { tokenAuth: this.tokenAuth, datos: dataEncripted}).then(response => {
            this.isDisabled = false



            document.getElementById('btn_transferir').innerHTML = 'Transferir criptomonedas';


            if (response.data.code == 200) {

              this.date= response.data.date;
              this.mount= this.mountSend;
              this.title="Transacción exitosa";

              this.showFormCripto=false;

              this.successPay=true;          

              this.$toast.success(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              });

              this.$eventBus.$emit('reiniciarBalanceCripto')
              this.$eventBus.$emit('reiniciarTransaccionesCripto')


            } else {
              this.isDisabled = false

              this.$toast.error(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })

              if (response.data.code == 401) {
                
                localStorage.removeItem('userData')



                
                

              

                
                

                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
              } else if (response.data.code == 503) {
                this.pin = ''
              } else if(response.data.code == 501){
                      this.$swal({
                          title: 'Tu perfil debe estar verificado para realizar esta acción',
                          text: `¿Deseas verificar tu cuenta ahora?`,
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Verificar cuenta',
                          cancelButtonText: 'No',
                          customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-secondary ml-1',
                          },
                          buttonsStyling: false,
                        }).then(result => {
                          if (result.value) {

                            this.$router.push({ name: 'perfil', hash: '#verificacion' })

                          }
                        })
                    }
            }
            }).catch(error => {
            this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
            })

            }

    },
    onSubmit(event) {
      event.preventDefault()

      if(Number(this.description.length) > Number(this.maxChar)){

        this.description="";

              this.$toast.error("El texto de la descripción sobrepasa el límite permitido de 75 caracteres", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

      }else{

        this.mount= Number(this.mount).toFixed(2);

        if(Number(this.description.length) > Number("0")){
          this.hasDescription=true;

        }else{
          this.hasDescription=false;
        }

      const userId_json = {
        userIdWhoPay: this.userId, pin: this.pin, mount: this.mount, description: this.description, dataScan:this.dataScan, origen: this.origen
      }



      const user_string = JSON.stringify(userId_json)

      const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
    
      this.isDisabled = true

      document.getElementById('btn_transferir').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Transferiendo'

      this.$https.post('/interbilletera/payWithQR/', { tokenAuth: this.tokenAuth, data: dataEncripted}).then(response => {
      this.isDisabled = false

    
  
        document.getElementById('btn_transferir').innerHTML = 'Transferir';
       

        if (response.data.code == 200) {

          this.date= response.data.date;
          this.title="Transacción exitosa";

          this.showFormPay=false;

          this.successPay=true;          

          this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          });

          this.$eventBus.$emit('reiniciarListadoInterbilletera');

          this.$eventBus.$emit('reiniciarEnviar')

          this.$eventBus.$emit('reiniciarEnviar2');
          this.$eventBus.$emit('reiniciarTransaccionesHome');


        } else {
          this.isDisabled = false

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')


            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pin = ''
          } else if(response.data.code == 501){
                  this.$swal({
                      title: 'Tu perfil debe estar verificado para realizar esta acción',
                      text: `¿Deseas verificar tu cuenta ahora?`,
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Verificar cuenta',
                      cancelButtonText: 'No',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-secondary ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (result.value) {

                        this.$router.push({ name: 'perfil', hash: '#verificacion' })

                      }
                    })
                }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })

      }

    
    },
    onBlurMount(){
    

      if(this.mount == null || this.mount == "null" || this.mount ==""){



        }else{

              if(Number(this.mount) > Number(this.saldoDisponible)){
              

                  this.mount="";

                   this.$toast.error("La cantidad a enviar es mayor a su saldo disponible", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                      })

          


              }

        


        }

    },



  },
}
</script>

<style lang="scss">



</style>
